import React, {useEffect, useState} from "react";
import {useUserAuth} from "../context/UserAuthContext";
import {Link} from "react-router-dom";
import {getData} from "../helpers/HttpService";
import ReactQuill from "react-quill";

export const Home = () => {
    const {user} = useUserAuth();
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        getData("homepage/", user.accessToken).then((data) =>
            setBoxes(data.sort((a, b) => a.order - b.order))
        );
    }, []);

    const renderBoxContent = (box) => {
        // Extrahiere die Überschrift und den restlichen Content
        const match = box.content.match(/<p[^>]*>(.*?)<\/p>/); // Erstes <p> Element
        const heading = match ? match[0] : ""; // Die vollständige Überschrift (inklusive Tags)
        const restContent = box.content.replace(heading, ""); // Restlicher Content ohne Überschrift

        // Prüfe, ob die Überschrift "Familienbüro" enthält
        const containsFamilienbuero = heading.includes("Familienbüro");

        return (
            <>
                {box.showLogo && (
                    <img
                        src="./images/fra-uas-logo.svg"
                        alt="UAS-Logo"
                        className="uas-logo"
                    />
                )}

                <ReactQuill
                    value={heading}
                    readOnly={true}
                    theme="bubble"
                    modules={{toolbar: false}}
                />

                {containsFamilienbuero && (
                    <img
                        src="./images/famburo_banner.jpg"
                        alt="Familienbüro Banner"
                        className="familienbuero-banner"
                    />
                )}

                <ReactQuill
                    value={restContent}
                    readOnly={true}
                    theme="bubble"
                    modules={{toolbar: false}}
                />
            </>
        );
    };


    return (
        <div className="container-contents">
            {user.email?.includes("@united-parents.de") ||
            user.email?.includes("christina.bender@stud.fra-uas.de") ||
            user.email === "denis.neumann@outlook.de" ? (
                <Link className="button go-to" to="/editHomePage">
                    Seite Bearbeiten
                </Link>
            ) : null}
            {boxes.map((box, index) => (
                <div key={index} className="box-content">
                    {renderBoxContent(box)}
                </div>
            ))}
        </div>
    );
};
