import React, {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {getData, postData} from "../helpers/HttpService";
import {useUserAuth} from "../context/UserAuthContext";
import {useNavigate} from "react-router-dom";

export const EditHomePage = () => {
    const navigate = useNavigate();
    const {user} = useUserAuth();
    const [boxes, setBoxes] = useState([]);

    // Fetch boxes data on component load
    useEffect(() => {
        getData("homepage/", user.accessToken).then((data) =>
            setBoxes(data.sort((a, b) => a.order - b.order))
        );
    }, []);

    // Add a new empty box
    const handleAddBox = () => {
        setBoxes([
            ...boxes,
            {id: Date.now().toString(), content: "", showLogo: false, order: boxes.length + 1},
        ]);
    };

    // Delete a box
    const handleDeleteBox = (index) => {
        const newBoxes = [...boxes];
        newBoxes.splice(index, 1);

        // Recalculate order of remaining boxes
        const updatedBoxes = newBoxes.map((box, i) => ({...box, order: i + 1}));
        setBoxes(updatedBoxes);
    };

    // Update content of a box
    const handleContentChange = (content, index) => {
        const newBoxes = [...boxes];
        newBoxes[index].content = content;
        setBoxes(newBoxes);
    };

    // Move a box up in order
    const moveBoxUp = (index) => {
        if (index === 0) return; // Already at the top
        const newBoxes = [...boxes];
        [newBoxes[index], newBoxes[index - 1]] = [newBoxes[index - 1], newBoxes[index]]; // Swap
        setBoxes(newBoxes.map((box, i) => ({...box, order: i + 1}))); // Recalculate order
    };

    // Move a box down in order
    const moveBoxDown = (index) => {
        if (index === boxes.length - 1) return; // Already at the bottom
        const newBoxes = [...boxes];
        [newBoxes[index], newBoxes[index + 1]] = [newBoxes[index + 1], newBoxes[index]]; // Swap
        setBoxes(newBoxes.map((box, i) => ({...box, order: i + 1}))); // Recalculate order
    };

    // Toggle logo display for a box
    const handleLogoToggle = (index) => {
        const newBoxes = [...boxes];
        newBoxes[index].showLogo = !newBoxes[index].showLogo;
        setBoxes(newBoxes);
    };

    // Save the current state of boxes to the backend
    const handleSave = async () => {
        try {
            await postData("homepage/", {boxes}, user.accessToken);
            navigate("/home");
        } catch (error) {
            console.error("Error saving boxes:", error);
            alert("Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es erneut.");
        }
    };

    // Quill modules and formats
    const modules = {
        toolbar: [
            [{size: ["large", "huge"]}],
            ["bold", "italic", "underline", "strike"],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}, {align: []}],
            ["link", "image"],
            ["blockquote"],
            [{color: []}, {background: []}],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
        "color",
        "background",
    ];

    return (
        <div className="container-contents">
            {/* Add New Box Button */}
            <div className="add-panel" onClick={handleAddBox}>
                <span>+</span>
            </div>

            {/* Render Editable Boxes */}
            {boxes.map((box, index) => (
                <div key={box.id} className="editor-box">
                    {/* ReactQuill Editor */}
                    <ReactQuill
                        value={box.content}
                        onChange={(content) => handleContentChange(content, index)}
                        modules={modules}
                        formats={formats}
                    />

                    {/* Controls */}
                    <div className="controls">
                        {/* Show Logo Checkbox */}
                        <label>
                            <input
                                type="checkbox"
                                checked={box.showLogo}
                                onChange={() => handleLogoToggle(index)}
                            />
                            UAS-Logo
                        </label>

                        {/* Order Buttons */}
                        <label>
                            Reihenfolge:
                            <div
                                onClick={() => {
                                    if (index > 0) moveBoxUp(index)
                                }}
                            >
                                <span className="material-icons">keyboard_double_arrow_up</span>
                            </div>
                            <div
                                onClick={() => {
                                    if (index < boxes.length - 1) moveBoxDown(index)
                                }}
                            >
                                <span className="material-icons">keyboard_double_arrow_down</span>
                            </div>
                        </label>

                        {/* Delete Box Button */}
                        <button className="delete-button" onClick={() => handleDeleteBox(index)}>
                            &times;
                        </button>
                    </div>
                </div>
            ))}

            {/* Save Button */}
            <button className="button" onClick={handleSave}>
                Speichern
            </button>
        </div>
    );
};
